/**
 * Capitalizes City Names
 * @param {string} arg The city the user entered on the front-end.
 */
const validateCity = (city) => {
  const cityArr = city.split(" ");
  const capitalizedArr = [];

  if (cityArr[1]) {
    // Handle cities w/ 2+ words
    for (let i = 0; i < cityArr.length; i++) {
      const el = cityArr[i];
      const capitalized = capitalize(el);
      capitalizedArr.push(capitalized);
    }
    const capitalizedString = capitalizedArr.join(" ");
    return capitalizedString;
  } else {
    const capitalized = capitalize(city);
    return capitalized;
  }
};

/**
 * Capitalizes First letter of word (dAllaS --> Dallas)
 * @param {string} arg The word to be capitalized.
 */
const capitalize = (word) => {
  const lowercase = word.toLowerCase();
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

/**
 * Removes Special Characters ()-
 * @param {string} arg The phone number the user entered on the front-end.
 */
const replaceSpecialChar = (value) => {
  if (typeof value !== "string") {
    // Make sure it is a string
    return value.toString().replace(/[-()]/g, "");
  } else {
    return value.replace(/[-()]/g, "");
  }
};

/**
 * Formats Phone Numer for display --> (XXX)XXX-XXXX
 * @param {string} arg The phone number the user entered on the front-end.
 */
const formatPhone = (num) => {
  return `${num.substring(4) ? "(" : ""}${num.substring(0, 3)}${
    num.substring(4) ? ")" : ""
  }${num.substring(3, 6)}${num.substring(7) ? "-" : ""}${num.substring(6, 10)}`;
};

/**
 * Remove an Element from an Array
 * @param {object} arg The array
 * @param {string} arg The element to be removed
 */
const removeFromArr = (arr, el) => {
  var elIndex = arr.indexOf(el);
  arr.splice(elIndex, 1);

  return arr;
};

/**
 * Check if array already contains element
 * @param {object} arg The array
 * @param {string} arg The element to check for
 */
const arrayContains = (arr, el) => {
  if (arr.indexOf(el) !== -1) {
    return true;
  } else {
    return false;
  }
};

/**
 * Scroll to top on Footer link click
 */
const scrollToTop = () => {
  window.scrollTo(0, 0);
};

/**
 * Control Iframe URL (if localhost, use localhost)
 */
const getCurrentURl = () => {
  const url = window.location.href;
  if (url.includes("localhost")) {
    const splitURL = url.split("/");
    return `${splitURL[0]}//${splitURL[2]}/`;
  } else {
    return `https://findahomeinusa.com/`;
  }
};

module.exports = {
  validateCity,
  capitalize,
  formatPhone,
  removeFromArr,
  arrayContains,
  replaceSpecialChar,
  scrollToTop,
  getCurrentURl,
};

import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import EmailIcon from "../../../assets/email.png";
import PhoneIcon from "../../../assets/phone.png";
import { getLenderProfile } from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import imagePath from "../../../utils/image";

const Profile = () => {
  const navigate = useNavigate();
  const [agent, setAgent] = useState([]);
  const [areas, setAreas] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const displayPhoto = (image) => {
    // console.log(image);
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `${imagePath}/${image}`;
    }
  };

  const getArea = () => {
    const searchTerm = localStorage.getItem("agentSearchTerm");
    if (searchTerm) {
      return searchTerm;
    } else {
      const city = agent.areas_served.split("; ")[0];
      return city;
    }
  };

  const url = window.location.href;
  const agent_id = url.split("/")[4];

  useEffect(() => {
    // Get agent Info
    getLenderProfile(agent_id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);

        setAgent(data);
        setAreas(data.areas_served.split("; "));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <Header />
        <div className="min-height"></div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="breadcrumb">
        <p className="text">Find A Home Lender Directory / </p>
        <p
          className="back-button"
          onClick={() => {
            navigate(`/find-a-lender`);
          }}
        >
          Return To Search
        </p>
      </div>

      <main className="agent-profile-page">
        <div className="agent-col-1">
          <h1>
            {agent.first_name} {agent.last_name}
          </h1>

          <img
            src={displayPhoto(agent.image)}
            alt="Agent Headshot"
            className={!agent.image ? "agent-photo" : "agent-photo with-border"}
          />

          <div className="agent-info">
            <h2>Contact Information</h2>
            <div className="flex">
              <img src={EmailIcon} alt="Email Icon" className="icon" />
              <p>{agent.email}</p>
            </div>
            <div className="flex">
              <img src={PhoneIcon} alt="Email Icon" className="icon" />
              <p>{agent.phone_number}</p>
            </div>
          </div>

          <div className="agent-info">
            <h2>Languages</h2>
            <p>{agent.languages_spoken}</p>
          </div>

          <div className="agent-info">
            <h2>State License Number</h2>
            <p>{agent.state_license_numbers}</p>
          </div>

          <div className="agent-info">
            <h2>Company</h2>
            <p>
              {agent.company} {agent.company_number}
            </p>
          </div>

          <div className="agent-info">
            <h2>Areas Served</h2>
            {areas.map((area) => (
              <p key={area}>{area}</p>
            ))}
          </div>
        </div>
        <div className="agent-col-2">
          <p>{agent.profile_description}</p>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Profile;

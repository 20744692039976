import React, { useState } from "react";
import "./style.css";
import { postNotice } from "../../../utils/api";
import moment from "moment";

const Modal = ({ setModalOpen }) => {
  const [userInput, setUserInput] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
  });

  const [success, setSuccess] = useState(false);

  // TODO: Send email on the backend
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userInput);

    const noticeData = {
      content: `${userInput.fname} ${userInput.lname} has submitted an Enterprise Plan Demo Request! Email: ${userInput.email} Phone Number: ${userInput.phone}`,
      date: moment(),
      actions: "",
      website: "Findahome",
      user_id: null,
      agent_id: null,
    };

    // Fetch 4: Alert the Super User
    postNotice(noticeData)
      .then((res) => res.json())
      .then((data) => {
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="modal pricing-modal">
      {success ? (
        <div className="modal-container">
          <div className="success-message">
            <h3>
              Thank you for your response, we'll be in touch with you shortly!
            </h3>

            <button
              onClick={() => {
                setSuccess(false);
                setModalOpen(false);
              }}
              className="success-close-btn"
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <div className="modal-container">
          <button
            onClick={() => setModalOpen(false)}
            className="modal-close-btn"
          >
            X
          </button>
          <h3>Enterprise Plan Demo Request</h3>

          <form onSubmit={(e) => handleSubmit(e)}>
            <label htmlFor="fname">
              First Name<span>*</span>
            </label>
            <input
              type="text"
              id="fname"
              required
              value={userInput.fname}
              onChange={(e) =>
                setUserInput({ ...userInput, fname: e.target.value })
              }
            />

            <label htmlFor="lname">
              Last Name<span>*</span>
            </label>
            <input
              type="text"
              id="lname"
              required
              value={userInput.lname}
              onChange={(e) =>
                setUserInput({ ...userInput, lname: e.target.value })
              }
            />

            <label htmlFor="email">
              Email<span>*</span>
            </label>
            <input
              type="text"
              id="email"
              required
              value={userInput.email}
              onChange={(e) =>
                setUserInput({ ...userInput, email: e.target.value })
              }
            />

            <label htmlFor="phone">
              Phone Number<span>*</span>
            </label>
            <input
              type="text"
              id="phone"
              required
              value={userInput.phone}
              onChange={(e) =>
                setUserInput({ ...userInput, phone: e.target.value })
              }
            />

            <input type="submit" value="Submit" className="submit-btn" />
          </form>
        </div>
      )}
    </div>
  );
};

export default Modal;

// Packages
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Assets
import headerImg from "../../assets/3-1.png";
import "./admin.css";
// Scripts
import Auth from "../../utils/auth";
// Components
import Dashboard from "./dashboard";
import ManageUsers from "./manageUsers";
import ManageBuscaUsers from "./manageBuscaUsers";
import ManageLenders from "./manageLenders";
import ManageBuscaLenders from "./manageBuscaLenders";

const Admin = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState("Dashboard");

  // Run Query Once
  useEffect(() => {
    // User token
    let currentUser;
    if (Auth.loggedIn() !== false) {
      currentUser = Auth.getUser();
      if (currentUser.user_type === "Super User") {
        setLoading(false);
      } else {
        navigate(`/user-login`);
      }
    } else {
      navigate(`/user-login`);
    }
  }, []);

  if (isLoading) {
    return <>loading...</>;
  }

  const getCurrentPage = () => {
    switch (currentPage) {
      case "Dashboard":
        return <Dashboard />;
      case "Find A Home Users":
        return <ManageUsers />;
      case "Busca Tu Casa Users":
        return <ManageBuscaUsers />;
      case "Find A Home Lenders":
        return <ManageLenders />;
      case "Busca Tu Casa Lenders":
        return <ManageBuscaLenders />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="admin">
      {/* Navbar */}
      <header>
        <div className="admin-hamburg" onClick={() => setOpenMenu(!openMenu)}>
          &#9776;
        </div>

        <div
          onClick={() => setCurrentPage("Dashboard")}
          className="admin-logo-container"
        >
          <img className="admin-logo" alt="Find A Home Logo" src={headerImg} />
        </div>

        <div>
          <div
            className={isOpen === false ? "" : "nav-open"}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <p>Admin</p>
            <div
              className={isOpen === false ? "admin-menu" : "admin-menu open"}
            >
              <ul>
                <li onClick={() => Auth.logout()}>Logout</li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      {/* Sidebar */}
      <div
        className={openMenu === true ? "admin-sidebar open" : "admin-sidebar"}
      >
        <ul>
          <li
            className={currentPage === "Dashboard" ? "highlight" : ""}
            onClick={() => {
              setCurrentPage("Dashboard");
              setOpenMenu(!openMenu);
            }}
          >
            Dashboard
          </li>
          <li
            className={currentPage === "Find A Home Users" ? "highlight" : ""}
            onClick={() => {
              setCurrentPage("Find A Home Users");
              setOpenMenu(!openMenu);
            }}
          >
            Find A Home Agents
          </li>
          <li
            className={currentPage === "Find A Home Lenders" ? "highlight" : ""}
            onClick={() => {
              setCurrentPage("Find A Home Lenders");
              setOpenMenu(!openMenu);
            }}
          >
            Find A Home Lenders
          </li>
          <li
            className={currentPage === "Busca Tu Casa Users" ? "highlight" : ""}
            onClick={() => {
              setCurrentPage("Busca Tu Casa Users");
              setOpenMenu(!openMenu);
            }}
          >
            Busca Tu Casa Agents
          </li>
          <li
            className={
              currentPage === "Busca Tu Casa Lenders" ? "highlight" : ""
            }
            onClick={() => {
              setCurrentPage("Busca Tu Casa Lenders");
              setOpenMenu(!openMenu);
            }}
          >
            Busca Tu Casa Lenders
          </li>
        </ul>
      </div>

      {/* Main */}
      <main>{getCurrentPage()}</main>
    </div>
  );
};

export default Admin;

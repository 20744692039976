import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete from "../../../components/PlacesAutocomplete";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import {
  removeFromArr,
  arrayContains,
  replaceSpecialChar,
  formatPhone,
  getCurrentURl,
} from "../../../utils/helpers";
import {
  addFile,
  addLender,
  createUser,
  postNotice,
  sendWelcome,
  sendConfirmation,
} from "../../../utils/api";

const Signup = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [languages, setLanguages] = useState("");
  const [primaryLanguage, setPrimaryLanguage] = useState("English");
  const [areas, setAreas] = useState([]);
  const [license, setLicense] = useState("");
  const [brokerage, setBrokerage] = useState("");
  const [brokerageNumber, setBrokerageNumber] = useState("");
  const [description, setDescription] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setisVisible] = useState(false);
  const [isBothWebsites, setBothSites] = useState(false);

  const [formattedAddress, setFormattedAddress] = React.useState("");

  const [showError, setShowError] = useState(false);
  const [showImageError, setShowImageError] = useState(false);
  const [showAreasError, setShowAreasError] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);

  const [canSubmit, setCanSubmit] = useState(false);

  // ---------------- MESSAGE CENTER -----------------
  window.onmessage = function (event) {
    // console.log(event.data);
    // Listens for Parent Form Submission
    if (typeof event.data === "string" && event.data.includes("fileName")) {
      // Lets the Parent Window know if an image has been uploaded
      const fileName = event.data.split(":")[1];
      setProfileImage(fileName);
      saveData(fileName);
    } else if (
      typeof event.data === "string" &&
      event.data.includes("No Image For Upload")
    ) {
      setShowImageError(true);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const iframe = document.querySelector("#image-upload");
    iframe.contentWindow.postMessage(`Submitting`, "*");
  };

  const saveData = async (fileName) => {
    let profileFile = "";
    profileFile = fileName;

    if (areas[0]) {
      const userData = {
        name: `${firstName} ${lastName}`,
        email: email,
        password: password,
        user_type: "Lender",
      };

      const formData = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        email: email,
        languages_spoken: languages,
        primary_language: primaryLanguage,
        areas_served: areas.join("; "),
        state_license_numbers: license,
        profile_description: description,
        company: brokerage,
        company_number: brokerageNumber,
        isDeleted: true,
      };

      // Fetch 1: Create the User
      const response = await createUser(userData);

      if (!response.ok) {
        const errorMessage = await response.json();
        const message = errorMessage.errors[0].message;

        // Handle Unique Email Validation Error
        if (message === "email must be unique") {
          console.log("Email Should be Unique");
          setEmailAlert(true);
        } else {
          console.log(message);
          setShowError(true);
        }
      } else {
        const data = await response.json();
        formData.user_id = data.id;

        if (profileFile !== "") {
          formData.image = profileFile;
          // Fetch 3: Create the Agent
          createAgent(formData);
        } else {
          createAgent(formData);
        }
      }

      async function createAgent(formData) {
        const response = await addLender(formData);

        if (!response.ok) {
          const errorMessage = await response.json();
          const message = errorMessage.errors[0].message;

          // Handle Unique Email Validation Error
          if (message === "email must be unique") {
            console.log("Email Should be Unique");
            setEmailAlert(true);
          } else {
            console.log(message);
            setShowError(true);
          }
        } else {
          const data = await response.json();
          const agent = data.newLender;

          const noticeData = {
            content: `${firstName} ${lastName} created an Lender Account`,
            date: moment(),
            actions: "Approve Lender",
            actions_content: agent.id,
            website: "Findahome",
            user_id: agent.user_id,
            agent_id: agent.id,
          };

          // Fetch 4: Alert the Super User
          postNotice(noticeData)
            .then((res) => res.json())
            .then((data) => {
              // Fetch 5: Send the welcome email to the agent
              sendWelcome({ email: email })
                .then((res) => res.json())
                .then((data) => {
                  // Fetch 6: Send Santiago an email that an agent has signed up
                  sendConfirmation()
                    .then((res) => res.json())
                    .then((data) => {
                      navigate(`/signup-success`);
                    })
                    .catch((err) => {
                      console.log(err);
                      setShowError(true);
                    });
                })
                .catch((err) => {
                  console.log(err);
                  setShowError(true);
                });
            })
            .catch((err) => {
              console.log(err);
              setShowError(true);
            });
        }
      }
    } else {
      setShowAreasError(true);
    }
  };

  // Check if User can submit
  useEffect(() => {
    if (description.length > 1500) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [description]);

  return (
    <div>
      <Header />

      <main>
        <div className="contact-form-container home-loans ">
          <div className="loans-main-wrapper">
            <div className="loans-form-wrapper">
              {/* <!-- Form --> */}
              <form
                onSubmit={handleFormSubmit}
                autoComplete="off"
                className="agent-signup-form"
              >
                <div className="loans-form-title agent-signup">
                  <h2 className="">We're excited to start working with you!</h2>
                  <p>
                    To set up your lender profile, fill out the information
                    below.
                  </p>
                </div>
                <hr className="loans-form-hr" />
                <div className="loans-input-flex">
                  <div>
                    <label htmlFor="firstname" className="loans-form-label">
                      First name *
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      className="loans-form-input"
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="lastname" className="loans-form-label">
                      {" "}
                      Last name *
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      className="loans-form-input"
                      required
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="loans-input-flex">
                  <div>
                    <label htmlFor="email" className="loans-form-label">
                      {" "}
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="loans-form-input"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="loans-form-label">
                      {" "}
                      Phone number *
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="loans-form-input"
                      required
                      value={phoneNumber}
                      onChange={(e) => {
                        let num = replaceSpecialChar(e.target.value);
                        const phone = formatPhone(num);
                        setPhoneNumber(phone);
                      }}
                    />
                  </div>
                </div>

                <div className="loans-mb-3">
                  <div>
                    <label htmlFor="languages" className="loans-form-label">
                      {" "}
                      Languages Spoken *
                    </label>
                    <input
                      type="languages"
                      name="languages"
                      id="languages"
                      className="loans-form-input"
                      required
                      value={languages}
                      onChange={(e) => setLanguages(e.target.value)}
                    />
                  </div>
                  <div style={{ display: "none" }}>
                    <label
                      htmlFor="primaryLanguage"
                      className="loans-form-label"
                    >
                      {" "}
                      Website *
                    </label>
                    <select
                      className="agent-form-select"
                      id="primaryLanguage"
                      name="primaryLanguage"
                      value={primaryLanguage}
                      onChange={(e) => {
                        if (e.target.value === "Both") {
                          setBothSites(true);
                        } else {
                          setBothSites(false);
                        }
                        setPrimaryLanguage(e.target.value);
                      }}
                      required
                    >
                      <option value={"English"}>Find A Home</option>
                      {/* <option value={"Spanish"}>Busca Tu Casa</option>
                      <option value={"Both"}>
                        Find A Home & Busca Tu Casa
                      </option> */}
                    </select>
                  </div>
                </div>

                <div>
                  <p className="areas-text">Areas Served: * </p>
                  {areas.map((area) => (
                    <div key={area} id={area} className="area-btn">
                      {area}
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          console.log(area);
                          // Remove from State
                          const updatedArr = removeFromArr(areas, area);
                          setAreas(updatedArr);
                          // Hide
                          document.getElementById(`${area}`).style.display =
                            "none";
                        }}
                      >
                        X
                      </span>
                    </div>
                  ))}
                  {showAreasError ? (
                    <p className="error-message">
                      Please enter an area to submit
                    </p>
                  ) : (
                    <></>
                  )}

                  <div className="loans-input-flex">
                    <div>
                      <label htmlFor="city" className="loans-form-label">
                        City, State
                      </label>
                      <PlacesAutocomplete
                        setFormattedAddress={setFormattedAddress}
                        formattedAddress={formattedAddress}
                      />
                    </div>
                    <div>
                      <button
                        className="areas-btn"
                        onClick={(e) => {
                          e.preventDefault();

                          console.log(formattedAddress);

                          // Check for Content
                          const cityTrimmed = formattedAddress.trim();

                          if (cityTrimmed.length !== 0) {
                            // Check for Duplicates
                            const hasDuplicates = arrayContains(
                              areas,
                              formattedAddress
                            );
                            if (hasDuplicates === false) {
                              setAreas([...areas, formattedAddress]);
                            }
                          }
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <div className="loans-mb-3">
                  <label htmlFor="license" className="loans-form-label">
                    State License Numbers *
                  </label>
                  <input
                    type="text"
                    name="license"
                    id="license"
                    className="loans-form-input"
                    required
                    value={license}
                    onChange={(e) => setLicense(e.target.value)}
                  />
                </div>

                <div className="loans-input-flex">
                  <div className="loans-mb-3">
                    <label htmlFor="brokerage" className="loans-form-label">
                      Company Name *
                    </label>
                    <input
                      type="text"
                      name="brokerage"
                      id="brokerage"
                      className="loans-form-input"
                      required
                      value={brokerage}
                      onChange={(e) => setBrokerage(e.target.value)}
                    />
                  </div>
                  <div className="loans-mb-3">
                    <label
                      htmlFor="brokerage-number"
                      className="loans-form-label"
                    >
                      Company Phone Number *
                    </label>
                    <input
                      type="text"
                      name="brokerage-number"
                      id="brokerage-number"
                      className="loans-form-input"
                      value={brokerageNumber}
                      required
                      onChange={(e) => {
                        let num = replaceSpecialChar(e.target.value);
                        const phone = formatPhone(num);
                        setBrokerageNumber(phone);
                      }}
                    />
                  </div>
                </div>

                <div className="loans-mb-3">
                  <label htmlFor="description" className="loans-form-label">
                    Lender Profile Description *
                  </label>
                  {isBothWebsites ? (
                    <p style={{ color: "#536387" }}>
                      If you want your profile to be displayed on Busca Tu Casa
                      and Find A Home, please make sure to provide both an
                      English and a Spanish version of your profile description
                      below!
                    </p>
                  ) : (
                    <></>
                  )}
                  <textarea
                    type="text"
                    name="description"
                    id="description"
                    className="loans-form-input agent-signup-textarea"
                    value={description}
                    required
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <p
                    className={
                      canSubmit === false
                        ? "character-count"
                        : "character-count error"
                    }
                  >
                    {description.length} / 1500{" "}
                  </p>
                </div>

                {/* Iframe to Upload Image */}
                <iframe
                  id="image-upload"
                  src={`${getCurrentURl()}fahiu2024`}
                  title="Listing search"
                  className="agent-signup-form"
                  style={{
                    width: "100%",
                    height: "70px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    border: "none",
                  }}
                ></iframe>

                <div>
                  <label htmlFor="password" className="loans-form-label">
                    Password *
                  </label>
                  <input
                    type={isVisible === false ? "password" : "text"}
                    name="password"
                    id="password"
                    className="loans-form-input"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="password-span"
                    onClick={() => {
                      setisVisible(!isVisible);
                    }}
                  >
                    {isVisible === false ? "View" : "Hide"}
                  </span>
                </div>

                {showError ? (
                  <div className="message-container">
                    <p className="error-message">
                      Something has gone wrong with your request, please try
                      again later.
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {emailAlert === true ? (
                  <div className="message-container">
                    <p className="error-message">
                      Email address already in use.
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {showImageError === true ? (
                  <div className="message-container">
                    <p className="error-message">Please Upload an Image.</p>
                  </div>
                ) : (
                  <></>
                )}
                <button
                  className={
                    canSubmit === false ? "loans-btn" : "loans-btn disabled"
                  }
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Signup;

import "./style.css";
import check from "../../../assets/check.png";

const PricingGrid = ({
  handleFree,
  handleStandard,
  handlePro,
  handleEnterprise,
}) => {
  return (
    <div className="pricing-desktop">
      <div className="pricing-container">
        <div className="pricing-banner">
          <p>Pricing & Features</p>
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              {/* Free Heading */}
              <th className="pricing-th">
                <h2>Free</h2>
                <div>
                  <p className="pricing-price">$0</p>
                  <p className="pricing-conditions">Per Month</p>
                </div>
                <hr></hr>
                <p className="pricing-description">
                  Essential tools for staying connected, building referrals, and
                  keeping skills sharp with monthly trainings.
                </p>
              </th>
              {/* Standard Heading */}
              <th className="pricing-th">
                <h2>Standard</h2>
                <div>
                  <p className="pricing-price">$89</p>
                  <p className="pricing-conditions">Per Month</p>
                </div>
                <hr></hr>
                <p className="pricing-description">
                  Enhanced visibility and mobile access for agents ready to
                  boost their reach and client engagement.
                </p>
              </th>
              {/* Pro Heading */}
              <th className="pricing-th">
                <h2>Pro</h2>
                <div>
                  <p className="pricing-price">$197</p>
                  <p className="pricing-conditions">Per Month</p>
                </div>
                <hr></hr>
                <p className="pricing-description">
                  Streamlined CRM integration designed for solo agents or team
                  leaders aiming for efficient client management.
                </p>
              </th>
              {/* Enterprise Heading */}
              <th className="pricing-th">
                <h2>Enterprise</h2>
                <div>
                  <p className="pricing-conditions">Demo upon request</p>
                </div>
                <hr></hr>
                <p className="pricing-description">
                  Custom solutions tailored for teams and brokerages seeking a
                  branded experience to elevate their business.
                </p>
              </th>
            </tr>
          </thead>

          {/* Features */}
          <tbody>
            <tr>
              <td>Directory profile section on Find a Home/Busca Tu Casa</td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Referral Network</td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Marking Center</td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Monthly Zoom Trainings</td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Mobile App Access</td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Featured Website Profile</td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Mobile App to CRM Integration - Team Lead or Solo Agent (FUB &
                Sierra Only)
              </td>
              <td></td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Enhanced Marketing</td>
              <td></td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Custom App Build for Teams and Brokerages</td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <div className="check-container">
                  <img src={check} alt="Check" />
                </div>
              </td>
            </tr>
            <tr className="no-hover">
              <td></td>
              <td>
                <button
                  className="pricing-signup-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleFree();
                  }}
                >
                  Get Started
                </button>
              </td>
              <td>
                <button
                  className="pricing-signup-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStandard();
                  }}
                >
                  Buy Now
                </button>
              </td>
              <td>
                <button
                  className="pricing-signup-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePro();
                  }}
                >
                  Buy Now
                </button>
              </td>
              <td>
                <button
                  className="pricing-signup-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEnterprise();
                  }}
                >
                  Request Demo
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricingGrid;

import React from "react";
import Header from "../../components/Header";
import "./search.css";

const Search = () => {
  return (
    <div>
      <Header />

      {/* Iframe in the Page from Ruuster */}
      <div className="iframe-results-container">
        <iframe
          src='https://findahome.ruuster.com/agent/jason-galaz/iframe?address={"value":"ChIJPZDrEzLsZIgRoNrpodC5P30","label":"Nashville, TN","data":[{"offset":0,"value":"Nashville"},{"offset":11,"value":"TN"}],"source":"googleAutocomplete"}&type=Land&type=ManufacturedInPark&type=house&type=condo&type=townhome&type=co-op'
          title="Listing search"
          id="resultsFrame"
          style={{
            width: "100%",
            height: "calc(100vh - 108px)",
            overflow: "hidden",
            border: "none",
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default Search;

// Packages
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
// Assets
import "./admin.css";
// Scripts
import {
  getBuscaAgentByCity,
  getBuscaAgentByName,
  getBuscaAgentByEmail,
  getBuscaRecentAgents,
} from "../../utils/api";
// Components
import EditAgentProfile from "./editBuscaAgent";

const ManageBuscaUsers = () => {
  const [agents, setAgents] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  // Pagination
  const itemsPerPage = 10;
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(agents.slice(itemOffset, endOffset));
    // console.log(agents.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(agents.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, agents]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % agents.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  // Search Input
  const [hasSearched, setHasSearched] = useState(false);
  const [searchType, setSearchType] = useState("Name");
  const [userInput, setUserInput] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();

    if (userInput.trim() !== "") {
      switch (searchType) {
        case "Location":
          // Search by City
          getBuscaAgentByCity(userInput.trim())
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setAgents(data.filterCities);
              setHasSearched(true);
            })
            .catch((err) => console.log(err));
          break;
        case "Name":
          // Search by Name
          getBuscaAgentByName(userInput.trim())
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setAgents(data);
              setHasSearched(true);
            })
            .catch((err) => console.log(err));
          break;
        case "Email":
          // Search by Email
          getBuscaAgentByEmail(userInput.trim())
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setAgents(data);
              setHasSearched(true);
            })
            .catch((err) => console.log(err));
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    // Get All Agents on page load
    getBuscaRecentAgents()
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setAgents(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <div className="admin-manage-users">
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className="admin-manage-users busca">
      {/* Search Bar */}
      <div className="admin-search-form">
        <label htmlFor="searchType" className="hidden">
          Search Type
        </label>
        <select
          className="admin-form-select"
          id="searchType"
          name="searchType"
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
        >
          <option value={"Name"}>Name</option>
          <option value={"Email"}>Email</option>
          <option value={"Location"}>Location</option>
        </select>

        <label htmlFor="userInput" className="hidden">
          User Input
        </label>
        <input
          className="admin-search-input"
          type="text"
          id="userInput"
          name="userInput"
          placeholder="Search For Agents"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          autoComplete="off"
        />

        <button className="admin-search-btn text-shadow" onClick={handleSearch}>
          Search Now
        </button>
      </div>

      {/* Search Results */}
      <div className="manage-users-table">
        <h2>
          {hasSearched === true
            ? `${agents.length} Search Results`
            : `${agents.length} Recently Added Agents`}
        </h2>

        <table className="admin-notices ">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Areas Served</th>
              <th>Premium</th>
              <th>Actions</th>
            </tr>
          </thead>

          {currentItems === null || !currentItems[0] ? (
            <tbody>
              <tr>
                <td colSpan="5">No Results Found</td>
              </tr>
            </tbody>
          ) : (
            <Items
              currentItems={currentItems}
              setSelectedUser={setSelectedUser}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
            />
          )}
        </table>

        {/* Pagination Buttons */}
        <div className="admin-pagination-container">
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>

      {/* Modal */}
      {modalOpen === true ? (
        <EditAgentProfile
          setModalOpen={setModalOpen}
          selectedUser={selectedUser}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

const Items = ({ currentItems, setSelectedUser, setModalOpen, modalOpen }) => {
  return (
    <tbody>
      {currentItems.map((result) => (
        <tr key={result.id}>
          <td>
            {result.first_name} {result.last_name}
          </td>
          <td>{result.email}</td>
          <td>{result.areas_served}</td>
          <td>{result.isPremium === true ? "Yes" : "No"}</td>
          <td
            className="admin-action"
            onClick={() => {
              const userId = result.id;
              setSelectedUser(userId);
              setModalOpen(!modalOpen);
            }}
          >
            Edit
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default ManageBuscaUsers;

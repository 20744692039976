import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./privacy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <div className="privacy-policy">
        <h1>PRIVACY POLICY</h1>
        <p>
          <b>Last updated December 11, 2024</b>
        </p>
        <p>
          This Privacy Notice for Find a Home Inc. (&quot;we,&quot;
          &quot;us,&quot; or &quot;our&quot;), describes how and why we might
          access, collect, store, use, and/or share (&quot;process&quot;) your
          personal information when you use our services (&quot;Services&quot;),
          including when you:
        </p>
        <ul>
          <li>
            &bull; Visit our website at{" "}
            <a href="https://findahome.com/">https://findahome.com/</a>, or any
            website of ours that links to this Privacy Notice
          </li>
          <li>
            &bull; Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
        <p>
          <b>Questions or concerns?</b> Reading this Privacy Notice will help
          you understand your privacy rights and choices. We are responsible for
          making decisions about how your personal information is processed. If
          you do not agree with our policies and practices, please do not use
          our Services. If you still have any questions or concerns, please
          contact us at findahomeintn@gmail.com.
        </p>

        <h2>SUMMARY OF KEY POINTS</h2>
        <p>
          <b>
            This summary provides key points from our Privacy Notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </b>
        </p>
        <p>
          <b>What personal information do we process?</b> When you visit, use,
          or navigate our Services, we may process personal information
          depending on how you interact with us and the Services, the choices
          you make, and the products and features you use. Learn more about{" "}
          <a href="#what-information-we-collect">
            personal information you disclose to us
          </a>
          .
        </p>
        <p>
          <b>Do we process any sensitive personal information?</b> Some of the
          information may be considered &quot;special&quot; or
          &quot;sensitive&quot; in certain jurisdictions, for example your
          racial or ethnic origins, sexual orientation, and religious beliefs.
          We do not process sensitive personal information.
        </p>
        <p>
          <b> Do we collect any information from third parties? </b>We may
          collect information from public databases, marketing partners, social
          media platforms, and other outside sources. Learn more about{" "}
          <a href="#information-collected-from-other-sources">
            information collected from other sources.
          </a>
        </p>
        <p>
          <b>How do we process your information?</b> We process your information
          to provide, improve, and administer our Services, communicate with
          you, for security and fraud prevention, and to comply with law. We may
          also process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so.
        </p>
        <p>
          Learn more about{" "}
          <a href="#how-we-process-information">
            how we process your information.
          </a>
        </p>

        <p>
          <b>
            In what situations and with which parties do we share personal
            information?
          </b>{" "}
          We may share information in specific situations and with specific
          third parties. Learn more about{" "}
          <a href="#personal-information-sharing">
            when and with whom we share your personal information.
          </a>
        </p>
        <p>
          <b>How do we keep your information safe?</b> We have adequate
          organizational and technical processes and procedures in place to
          protect your personal information. However, no electronic transmission
          over the internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Learn more about{" "}
          <a href="#how-do-we-keep-your-information-safe">
            how we keep your information safe.
          </a>
        </p>
        <p>
          <b>What are your rights?</b> Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Learn more about{" "}
          <a href="#what-are-your-privacy-rights">your privacy rights.</a>
        </p>
        <p>
          <b>How do you exercise your rights?</b> The easiest way to exercise
          your rights is by visiting{" "}
          <a href="https://findahome.com/contact-us">
            https://findahome.com/contact-us
          </a>
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
        <p>
          <b>
            Want to learn more about what we do with any information we collect?
          </b>{" "}
          <a href="#privacy-notice-in-full">
            Review the Privacy Notice in full.
          </a>
        </p>

        <h2 id="privacy-notice-in-full">TABLE OF CONTENTS</h2>
        <ol>
          <li>
            <a href="#what-information-we-collect">
              WHAT INFORMATION DO WE COLLECT?
            </a>
          </li>
          <li>
            <a href="#how-we-process-information">
              HOW DO WE PROCESS YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a href="#personal-information-sharing">
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </li>
          <li>
            <a href="#use-of-cookies">
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </a>
          </li>
          <li>
            <a href="#how-long-do-we-keep-information">
              HOW LONG DO WE KEEP YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a href="#how-do-we-keep-your-information-safe">
              HOW DO WE KEEP YOUR INFORMATION SAFE?
            </a>
          </li>
          <li>
            <a href="#do-we-collect-information-from-minors">
              DO WE COLLECT INFORMATION FROM MINORS?
            </a>
          </li>
          <li>
            <a href="#what-are-your-privacy-rights">
              WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#do-not-track-features">
              CONTROLS FOR DO-NOT-TRACK FEATURES
            </a>
          </li>
          <li>
            <a href="#specific-privacy-rights">
              DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#notice-updates">DO WE MAKE UPDATES TO THIS NOTICE?</a>
          </li>
          <li>
            <a href="#notice-contact">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
          </li>
          <li>
            <a href="#review-update-or-delete-data-collected">
              HOW CAN YOU REVIEW. UPDATE. OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
          </li>
        </ol>

        <div id="what-information-we-collect">
          <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
          <p>
            <b>Personal information you disclose to us</b>
          </p>
          <p>
            <b>In Short:</b>{" "}
            <i>We collect personal information that you provide to us.</i>
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p>
            <b>Personal Information Provided by You.</b> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>
          <ul>
            <li>&bull; names</li>
            <li>&bull; phone numbers</li>
            <li>&bull; email addresses</li>
            <li>&bull; malling addresses</li>
            <li>&bull; job titles</li>
            <li>&bull; usernames</li>
            <li>&bull; passwords</li>
            <li>&bull; contact preferences</li>
            <li>&bull; contact or authentication data</li>
          </ul>

          <p>
            <b>Sensitive Information.</b> We do not process sensitive
            information.
          </p>
          <p>
            <b>Payment Data.</b> We may collect data necessary to process your
            payment if you choose to make purchases, such as your payment
            instrument number, and the security code associated with your
            payment instrument. All payment data is handled and stored by
            Stripe. You may find their privacy notice link(s) here:{" "}
            <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>

          <p>
            <b>Information automatically collected</b>
          </p>
          <p>
            <b>In Short:</b>{" "}
            <i>
              Some information - such as your Internet Protocol (IP) address
              and/or browser and device characteristics - is collected
              automatically when you visit our Services.
            </i>
          </p>
          <p>
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>
          <p>The information we collect includes:</p>
          <ul>
            <li>
              &bull; <i>Log and Usage Data</i>. Log and usage data is
              service-related, diagnostic, usage, and performance information
              our servers automatically collect when you access or use our
              Services and which we record in log files. Depending on how you
              interact with us, this log data may include your IP address,
              device information, browser type, and settings and information
              about your activity in the Services (such as the date/time stamps
              associated with your usage, pages and files viewed, searches, and
              other actions you take such as which features you use), device
              event information (such as system activity, error reports
              (sometimes called "crash dumps"), and hardware settings).
            </li>
            <li>
              &bull; <i>Device Data</i>. We collect device data such as
              information about your computer, phone, tablet, or other device
              you use to access the Services. Depending on the device used, this
              device data may include information such as your IP address (or
              proxy server), device and application identification numbers,
              location, browser type, hardware model, Internet service provider
              and/or mobile carrier, operating system, and system configuration
              information.
            </li>
            <li>
              &bull; <i>Location Data</i>. We collect location data such as
              information about your device's location, which can be either
              precise or imprecise. How much information we collect depends on
              the type and settings of the device you use to access the
              Services. For example, we may use GPS and other technologies to
              collect geolocation data that tells us your current location
              (based on your IP address). You can opt out of allowing us to
              collect this information either by refusing access to the
              information or by disabling your Location setting on your device.
              However, if you choose to opt out, you may not be able to use
              certain aspects of the Services.
            </li>
          </ul>
          <p>
            <b>Google API</b>
          </p>
          <p>
            Our use of information received from Google APIs will adhere to{" "}
            <a href="https://developers.google.com/terms/api-services-user-data-policy">
              Google API Services User Data Policy
            </a>
            , including the{" "}
            <a href="https://developers.google.com/terms/api-services-user-data-policy#limited-use">
              Limited Use requirements
            </a>
            .
          </p>

          <p id="information-collected-from-other-sources">
            <b>Information collected from other sources</b>
          </p>

          <p>
            <b>In Short:</b>{" "}
            <i>
              We may collect limited data from public databases, marketing
              partners, and other outside sources.
            </i>
          </p>
          <p>
            In order to enhance our ability to provide relevant marketing,
            offers, and services to you and update our records, we may obtain
            information about you from other sources, such as public databases,
            joint marketing partners, affiliate programs, data providers, and
            from other third parties. This information includes mailing
            addresses, job titles, email addresses, phone numbers, intent data
            (or user behavior data), Internet Protocol (IP) addresses, social
            media profiles, social media URLs, and custom profiles, for purposes
            of targeted advertising and event promotion.
          </p>
        </div>

        <div id="how-we-process-information">
          <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
          <p>
            <b>In Short</b>:{" "}
            <i>
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </i>
          </p>
          <p>
            <b>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </b>
          </p>
          <ul>
            <li>
              &bull;{" "}
              <b>
                To facilitate account creation and authentication and otherwise
                manage user accounts.{" "}
              </b>
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
              <br />
            </li>
            <li>
              {" "}
              &bull;{" "}
              <b>
                To deliver and facilitate delivery of services to the user.
              </b>{" "}
              We may process your information to provide you with the requested
              service.
            </li>
            <li>
              &bull;{" "}
              <b>To respond to user inquiries/offer support to users. </b>
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </li>
            <li>
              &bull; <b>To send administrative information to you.</b> We may
              process your information to send you details about our products
              and services, changes to our terms and policies, and other similar
              information.
            </li>
            <li>
              &bull; <b>To request feedback.</b> We may process your information
              when necessary to request feedback and to contact you about your
              use of our Services.
            </li>
            <li>
              &bull;{" "}
              <b>To send you marketing and promotional communications.</b> We
              may process the personal information you send to us for our
              marketing purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time.
              For more information, see{" "}
              <a href="#what-are-your-privacy-rights">
                "WHAT ARE YOUR PRIVACY RIGHTS?"
              </a>{" "}
              below.
            </li>
          </ul>
        </div>

        <div id="personal-information-sharing">
          <h2>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>

          <p>
            <b>In Short:</b>{" "}
            <i>
              We may share information in specific situations described in this
              section and/or with the following third parties.
            </i>
          </p>
          <p>
            Data will not be shared with third parties for marketing or
            promotional purposes.
          </p>
          <p>
            We may need to share your personal information in the following
            situations:
          </p>
          <ul>
            <li>
              &bull; <b>Business Transfers.</b> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              &bull; <b>When we use Google Maps Platform APIs.</b> We may share
              your information with certain Google Maps Platform APIs (e.g.,
              Google Maps API, Places API). Google Maps uses GPS, Wi-Fi, and
              cell towers to estimate your location. GPS is accurate to about 20
              meters, while Wi-Fi and cell towers help improve accuracy when GPS
              signals are weak, like indoors. This data helps Google Maps
              provide directions, but it is not always perfectly precise. We
              obtain and store on your device ("cache") your location. You may
              revoke your consent anytime by contacting us at the contact
              details provided at the end of this document.
            </li>
          </ul>
        </div>

        <div id="use-of-cookies">
          <h2>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
          <p>
            <b>In Short</b>:{" "}
            <i>
              We may use cookies and other tracking technologies to collect and
              store your information.
            </i>
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to gather information when you interact with our
            Services. Some online tracking technologies help us maintain the
            security of our Services and your account, prevent crashes, fix
            bugs, save your preferences, and assist with basic site functions.
          </p>
          <p>
            We also permit third parties and service providers to use online
            tracking technologies on our Services for analytics and advertising,
            including to help manage and display advertisements, to tailor
            advertisements to your interests, or to send abandoned shopping cart
            reminders (depending on your communication preferences). The third
            parties and service providers use their technology to provide
            advertising about products and services tailored to your interests
            which may appear either on our Services or on other websites.
          </p>
          <p>
            To the extent these online tracking technologies are deemed to be a
            "sale""sharing" (which includes targeted advertising, as defined
            under the applicable laws) under applicable US state laws, you can
            opt out of these online tracking technologies by submitting a
            request as described below under section{" "}
            <a href="#specific-privacy-rights">
              "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"
            </a>
          </p>
          <p>
            Specific information about how we use such technologies and how vou
            can refuse certain cookies is set out in our Cookie Notice.
          </p>
        </div>

        <div id="how-long-do-we-keep-information">
          <h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
          <p>
            <b>In Short</b>:{" "}
            <i>
              We keep your information for as long as necessary to fulfill the
              purposes outlined in this Privacy Notice unless otherwise required
              by law.
            </i>
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this Privacy Notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </div>

        <div id="how-do-we-keep-your-information-safe">
          <h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
          <p>
            <b>In Short</b>:{" "}
            <i>
              We aim to protect your personal information through a system of
              organizational and technical security measures.
            </i>
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
        </div>

        <div id="do-we-collect-information-from-minors">
          <h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
          <p>
            <b>In Short</b>:{" "}
            <i>
              We do not knowingly collect data from or market to children under
              18 years of age.
            </i>
          </p>
          <p>
            We do not knowingly collect, solicit data from, or market to
            children under 18 years of age, nor do we knowingly sell such
            personal information. By using the Services, you represent that you
            are at least 18 or that you are the parent or guardian of such a
            minor and consent to such minor dependent's use of the Services. If
            we learn that personal information from users less than 18 years of
            age has been collected, we will deactivate the account and take
            reasonable measures to promptly delete such data from our records.
            If you become aware of any data we may have collected from children
            under age 18, please contact us at findahomeintn@gmail.com.
          </p>
        </div>

        <div id="what-are-your-privacy-rights">
          <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
          <p>
            <b>In Short</b>:{" "}
            <i>
              You may review, change, or terminate your account at any time,
              depending on your country, province, or state of residence.
            </i>
          </p>
          <p>
            <b>
              <u>Withdrawing your consent:</u>
            </b>{" "}
            If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time.
          </p>
          <p>
            You can withdraw your consent at any time by contacting us by using
            the contact details provided in the section{" "}
            <a href="#notice-contact">
              "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
            </a>{" "}
            below.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <p>
            <b>
              <u>Opting out of marketing and promotional communications:</u>
            </b>{" "}
            You can unsubscribe from our marketing and promotional
            communications at any time by clicking on the unsubscribe link in
            the emails that we send, replying "STOP" or
          </p>
          <p>
            "UNSUBSCRIBE" to the SMS messages that we send, or by contacting us
            using the details provided in the section{" "}
            <a href="#notice-contact">
              "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
            </a>{" "}
            below. You will then be removed from the marketing lists. However,
            we may still communicate with you - for example, to send you
            service-related messages that are necessary for the administration
            and use of your account, to respond to service requests, or for
            other non-marketing purposes.
          </p>

          <p>
            <b>Account Information</b>
          </p>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul>
            <li>&bull; Contact us using the contact information provided.</li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at findahomeintn@gmail.com.
          </p>
        </div>

        <div id="do-not-track-features">
          <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage, no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this Privacy Notice.
          </p>
          <p>
            California law requires us to let you know how we respond to web
            browser DNT signals. Because there currently is not an industry or
            legal standard for recognizing or honoring DNT signals, we do not
            respond to them at this time.
          </p>
        </div>

        <div id="specific-privacy-rights">
          <h2>10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
          <p>
            <b>In Short</b>:{" "}
            <i>
              If you are a resident of California, Colorado, Connecticut,
              Delaware, Florida, Indiana, lowa, Kentucky, Minnesota, Montana,
              Nebraska, New Hampshire, New Jersey, Oregon, Tennessee, Texas,
              Utah, or Virginia, you may have the right to request access to and
              receive details about the personal information we maintain about
              you and how we have processed it, correct inaccuracies, get a copy
              of, or delete your personal information. You may also have the
              right to withdraw your consent to our processing of your personal
              information. These rights may be limited in some circumstances by
              applicable law. More information is provided below.
            </i>
          </p>
          <p>
            <b>Categories of Personal Information We Collect</b>
          </p>
          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A. Identifiers</td>
                <td>
                  {" "}
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  B. Personal information as defined in the California Customer
                  Records statute
                </td>
                <td>
                  {" "}
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under state or
                  federal law
                </td>
                <td>
                  Gender, age, date of birth, race and ethnicity, national
                  origin, marital status, and other demographic data
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>D. Commercial information</td>
                <td>
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>E. Biometric information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F. Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>G. Geolocation data</td>
                <td>Device location</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>H. Audio, electronic, sensory, or similar information</td>
                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information</td>
                <td>
                  {" "}
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>J. Education Information</td>
                <td>Student records and directory information</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from collected personal information</td>
                <td>
                  {" "}
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual's preferences and characteristics
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>L. Sensitive personal Information</td>
                <td></td>
                <td>NO</td>
              </tr>
              <tr></tr>
            </tbody>
          </table>

          <p>
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </p>
          <ul>
            <li>
              &bull; Receiving help through our customer support channels;
            </li>
            <li>&bull; Participation in customer surveys or contests; and</li>
            <li>
              &bull; Facilitation in the delivery of our Services and to respond
              to your inquiries.
            </li>
          </ul>

          <p>
            We will use and retain the collected personal information as needed
            to provide the Services or for:
          </p>
          <ul>
            {" "}
            <li>
              &bull; Category A - As long as the user has an account with us
            </li>
            <li>
              &bull; Category B - As long as the user has an account with us
            </li>
            <li>
              &bull; Category I - As long as the user has an account with us
            </li>
          </ul>

          <p>
            <b>Sources of Personal Information</b>
          </p>
          <p>
            Learn more about the sources of personal information we collect in
            <a href="#what-information-we-collect">
              "WHAT INFORMATION DO WE COLLECT?"
            </a>
          </p>
          <p>
            <b>How We Use and Share Personal Information</b>
          </p>
          <p>
            Learn more about how we use your personal information in the
            section,
            <a href="#how-we-process-information">
              "HOW DO WE PROCESS YOUR INFORMATION?"
            </a>
          </p>
          <p>
            <b>Will your information be shared with anyone else?</b>
          </p>
          <p>
            Data will not be shared with third parties for marketing or
            promotional purposes
          </p>
          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Learn more about how we disclose personal information to in the
            section,{" "}
            <a href="#personal-information-sharing">
              "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
            </a>
          </p>
          <p>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information.
          </p>
          <p>
            We have not disclosed, sold, or shared any personal information to
            third parties for a business or commercial purpose in the preceding
            twelve (12) months. We will not sell or share personal information
            in the future belonging to website visitors, users, and other
            consumers.
          </p>
          <p>
            <b>Your Rights</b>
          </p>
          <p>
            You have rights under certain US state data protection laws.
            However, these rights are not absolute, and in certain cases, we may
            decline your request as permitted by law. These rights include:
          </p>
          <ul>
            <li>
              &bull; Right to know whether or not we are processing your
              personal data
            </li>
            <li>&bull; Right to access your personal data</li>
            <li>&bull; Right to correct inaccuracies in your personal data</li>
            <li>&bull; Right to request the deletion of your personal data</li>
            <li>
              &bull; Right to obtain a copy of the personal data you previously
              shared with us
            </li>
            <li>
              &bull; Right to non-discrimination for exercising your rights
            </li>
            <li>
              &bull; Right to opt out of the processing of your personal data if
              it is used for targeted advertising (or sharing as defined under
              California's privacy law), the sale of personal data, or profiling
              in furtherance of decisions that produce legal or similarly
              significant effects ("profiling")
            </li>
          </ul>
          <p>
            Depending upon the state where you live, you may also have the
            following rights:
          </p>

          <ul>
            <li>
              &bull; Right to access the categories of personal data being
              processed (as permitted by applicable law, including Minnesota's
              privacy law)
            </li>
            <li>
              &bull; Right to obtain a list of the categories of third parties
              to which we have disclosed personal data (as permitted by
              applicable law, including California's and Delaware's privacy law)
            </li>
            <li>
              &bull; Right to obtain a list of specific third parties to which
              we have disclosed personal data (as permitted by applicable law,
              including Minnesota's and Oregon's privacy law)
            </li>
            <li>
              &bull; Right to review, understand, question, and correct how
              personal data has been profiled (as permitted by applicable law,
              including Minnesota's privacy law)
            </li>
            <li>
              &bull; Right to limit use and disclosure of sensitive personal
              data (as permitted by applicable law, including California's
              privacy law)
            </li>
            <li>
              &bull; Right to opt out of the collection of sensitive data and
              personal data collected through the operation of a voice or facial
              recognition feature (as permitted by applicable law, including
              Florida's privacy law)
            </li>
          </ul>
          <p>
            <b>How to Exercise Your Rights</b>
          </p>
          <p>
            To exercise these rights, you can contact us by visiting{" "}
            <a href="https://findahome.com/contact-us">
              https://findahome.com/contact-us
            </a>
            , by emailing us at findahomeintn@gmail.com, or by referring to the
            contact details at the bottom of this document.
          </p>
          <p>
            Under certain US state data protection laws, you can designate an
            authorized agent to make a request on your behalf.
          </p>
          <p>
            We may deny a request from an authorized agent that does not submit
            proof that they have been validly authorized to act on your behalf
            in accordance with applicable laws.
          </p>
          <p>
            <b>Request Verification</b>
          </p>
          <p>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. We will only use personal information provided in
            your request to verify your identity or authority to make the
            request. However, if we cannot verify your identity from the
            information already maintained by us, we may request that you
            provide additional information for the purposes of verifying your
            identity and for security or fraud-prevention purposes.
          </p>
          <p>
            If you submit the request through an authorized agent, we may need
            to collect additional information to verify your identity before
            processing your request and the agent will need to provide a written
            and signed permission from you to submit such request on your
            behalf.
          </p>
          <p>
            <b>Appeals</b>
          </p>
          <p>
            Under certain US state data protection laws, if we decline to take
            action regarding your request, you may appeal our decision by
            emailing us at findahomeintn@gmail.com. We will inform you in
            writing of any action taken or not taken in response to the appeal,
            including a written explanation of the reasons for the decisions. If
            your appeal is denied, you may submit a complaint to your state
            attorney general.
          </p>
          <p>
            <b>California "Shine The Light" Law</b>
          </p>
          <p>
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us by using the contact
            details provided in the section
            <a href="#notice-contact">
              "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
            </a>
          </p>
        </div>

        <div id="notice-updates">
          <h2>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
          <p>
            <b>In Short</b>:{" "}
            <i>
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </i>
          </p>
          <p>
            We may update this Privacy Notice from time to time. The updated
            version will be indicated by an updated "Revised" date at the top of
            this Privacy Notice. If we make material changes to this Privacy
            Notice, we may notify you either by prominently posting a notice of
            such changes or by directly sending you a notification. We encourage
            you to review this
          </p>
          <p>
            Privacy Notice frequently to be informed of how we are protecting
            your information.
          </p>
        </div>

        <div id="notice-contact">
          <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
          <p>
            If you have questions or comments about this notice, you may contact
            us by post at:
          </p>
          <p>
            Find a Home Inc.
            <br />
            3401 Mallory Lane #100, Franklin, TN
          </p>
        </div>

        <div id="review-update-or-delete-data-collected">
          <h2>
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h2>
          <p>
            Based on the applicable laws of your country or state of residence
            in the US, you may have the right to request access to the personal
            information we collect from you, details about how we have processed
            it, correct inaccuracies, or delete your personal information. You
            may also have the right to withdraw your consent to our processing
            of your personal information. These rights may be limited in some
            circumstances by applicable law. To request to review, update, or
            delete your personal information, please visit:{" "}
            <a href="https://findahome.com/contact-us">
              https://findahome.com/contact-us
            </a>
            .
          </p>
        </div>

        <br></br>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;

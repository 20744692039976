// Token stores the user's name, email, and type
import { jwtDecode } from "jwt-decode";

class Auth {
  getUser() {
    const token = jwtDecode(this.checkForToken());
    const user = token.data; // Get the User's data from token
    return user;
  }

  loggedIn() {
    const token = this.checkForToken();
    if (token === null) {
      return false; // no token available
    } else {
      const decoded = jwtDecode(token);
      // Check if Token is exp time is greater than the current time
      if (decoded.exp > Date.now() / 1000) {
        return true; // Token is still valid
      } else {
        return false; // Token is expired
      }
    }
  }

  checkForToken() {
    return localStorage.getItem("fah_session");
  }

  login(tokenData) {
    localStorage.setItem("fah_session", tokenData);
  }

  logout() {
    localStorage.removeItem("fah_session");
    window.location.assign("/"); // Send User to the homepage
  }

  buscaLogout() {
    localStorage.removeItem("fah_session");
  }
}

export default new Auth();
